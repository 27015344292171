// RaiLogo.tsx
import React, { CSSProperties } from 'react';

interface PropsType {
  style?: CSSProperties;
  className?: string;
  color?: string;
}

const RaiLogo = ({ style, className, color = '#000000' }: PropsType) => (
  <svg
    id="RAI"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 207.8 131.3"
    style={style}
    className={className}
  >
    <defs>
      <style>{`.cls-1{fill:${color};stroke-width:0px;}`}</style>
    </defs>
    <path
      className="cls-1"
      d="M31.71,58.6l1.51,2.35,17.82,20.56,2.27,2.79-.15,8.67-6.34,8.22v-.15l-3.32,3.96-6.34,7.64-5.89,7.49-.15,3.38.91,3.08v4.11l-7.25.59-1.96-.73,1.06-7.64v-2.5l-12.99-11.9L0,98.4l1.81-3.67,7.25-6.9,6.8,6.9-.91,2.79,2.72-.59,6.34,6.17.75-8.22.3-24.38-2.11-3.08-1.81-2.06.15-2.35-2.87-.88-6.04-7.64-2.27-1.62L1.96,43.33v-7.49l7.7-8.22,2.42-.44-1.06-1.62,3.78-3.82,8.91-11.46h.76l-.3-5.73-.75-3.38.6-1.17,9.06.15.15,4.26-.75,4.99v1.17l.91.15,2.27,2.64,3.93,3.82,4.08,2.64,8.91,7.49-8.76,8.96-5.44-3.82-6.19-5.87-.15,7.05.15,6.17-.45,18.8ZM16.61,38.77l8.61,11.01.15-6.46.76-13.81-.15-2.35-.15.15-2.72,3.52-1.81.44-4.68,7.49ZM31.26,78.72l.15,20.27v2.64l3.02-3.96,5.44-8.08-4.68-6.61-2.57-2.5v.15l-1.36-1.91Z"
    />
    <path
      className="cls-1"
      d="M78.98,72.41l.15,16.01.45,14.69.45,1.32.15,12.19-6.04,2.35-9.97-.88.91-23.35,2.27-5.73-1.36-1.91v-5.73l-.6-17.18-.3-7.64,1.21-2.94-.75-28.05-.6-12.63,11.93-4.7,24.01,20.27.91,3.67c.15.15,2.57-.59,2.72-.59l7.25,6.46-1.06,8.81-6.95,8.22-4.83,5.58-2.57,3.08-4.23,5.58c1.06,2.06,2.87,4.55,3.78,6.32v2.5c1.21,1.32,2.11,2.06,3.17,3.52l6.04,9.99v1.91l.91.44,2.11,4.26v-.15l2.72,5.58,4.98,5.14-.15,4.26-12.23,6.61-3.62-5.73-5.29-11.75.15-2.5-1.81-.88-5.59-10.28-1.36-3.38-6.04-11.9-.91-.88ZM78.98,64.91l1.06-.59,7.55-9.11c2.11-2.5,5.74-7.05,7.7-9.69l1.66-2.06-3.02-3.67-1.06-.73-13.89-13.36-.3,11.31v1.47l-1.21,2.79.6,2.64.3.88.3,1.62.6,13.07-.3,5.43Z"
    />
    <path
      className="cls-1"
      d="M158.42,87.24l-2.11.29h-.15l-3.02,2.06-10.12,2.35-1.36.59-3.02,1.17-1.66,10.43-1.36,1.62,1.06,4.11-1.66,9.84-9.36-.88-5.29-3.23,4.08-17.92,1.51-9.25,1.66-1.62-.15-2.94,3.02-18.06.45-2.79.91-3.08v-2.64l4.53-19.68,2.72-3.08-1.66-2.2,1.06-5.87,1.36-6.17,1.51-9.25,12.38-.59,2.57,1.17-.15,5.14.45,1.17.6,1.47,4.83,22.47-1.06,2.64,1.81,1.32,7.25,35.4v2.06l3.78,13.66,1.51,9.25-.91,1.18,2.27,2.35,1.66,6.32-11.18,3.52h-1.96l-2.57-1.32-1.96-18.5-.6-4.11,1.21-1.47-1.36-.88-1.21-4.85-.3-1.17ZM142.86,69.03l.15-.15-1.81,9.55-.15,1.03,9.82-2.94,4.23-1.03-3.02-15.86-.6-7.64-.6-2.64-2.26-15.57-3.02,17.92-1.51.73.76,2.35-1.96,14.25Z"
    />
    <path
      className="cls-1"
      d="M192.25,50.96l-.45-29.37,1.21-2.64-1.36-.73-.75-7.34,7.1-.44,9.82.59-.75,10.72-.75,3.67-.45,19.24-.45,7.49.6,5.73.3,3.67-1.36,3.08,1.66,1.03v40.68l.3,2.2.3,2.79-.15,5.43-6.64,2.06-3.17-.15-6.95-.15,2.11-13.07,1.81-2.94-1.81-2.5v-32.75l.45-4.55.45-8.08-1.06-3.67Z"
    />
  </svg>
);

export default RaiLogo;
