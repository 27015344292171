import React, { useState, useEffect, useCallback } from 'react'
import { Wallet } from 'lucide-react'
import RaiLogo from '../assets/RaiLogo.tsx'
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseModule from '@web3-onboard/coinbase'
import { ethers } from 'ethers'
import raiAbi from '../abi/raiAbi.json'
import axios from 'axios'

const injected = injectedModule()
const coinbase = coinbaseModule()
const RPC_URL = 'https://rpc.vana.org'
const ID = '0x5C8'


const VANA_CHAIN = {
  id: ID,
  token: 'VANA',
  label: 'Vana',
  rpcUrl: RPC_URL
}

const onboard = Onboard({
  wallets: [injected, coinbase],
  chains: [
    {
      id: ID,
      token: 'VANA',
      label: 'Vana',
      rpcUrl: RPC_URL,
      blockExplorerUrl: 'https://islander.vanascan.io/',
      namespace: 'evm'
    }
  ],
  appMetadata: {
    name: 'Vana Mainnet Party',
    icon: '<svg>...</svg>',
    description: 'Vana Mainnet Party'
  }
})

// View state enum
const VIEW_STATES = {
  CONNECT: 'CONNECT',
  JOIN: 'JOIN',
  CLAIM: 'CLAIM'
}

const HowToEarnRAI = () => {
  return (
    <section className='text-left mb-24'>
      <h2 className='text-4xl font-bold text-black mb-4'>How to Earn $RAI:</h2>
      <ol className='list-decimal ml-6 space-y-4 text-black text-xl'>
        <li>
          <p className='font-bold'>Stake:</p>
          <ul className='list-disc ml-6'>
            <li>
              Minimum Stake: <strong>1 $VANA</strong> to YKYR DLP on{' '}
              <a
                href='https://datahub.vana.com/daos'
                target='_blank'
                className='text-blue-600 underline'
              >
                Vana Data Hub
              </a>
              .
            </li>
            <li>
              For reward calculations, each wallet’s stake is capped at <strong>1,000 $VANA</strong>.
            </li>
          </ul>
        </li>
        <li>
          <p className='font-bold'>Join the party:</p>
          <p>Join the party on the landing page.</p>
        </li>
        <li>
          <p className='font-bold'>Claim:</p>
          <p>
            Claim your $RAI share after the daily distribution is calculated.
          </p>
        </li>
      </ol>
    </section>
  )
}

const WhatPlan = () => {
  return (
    <section className='text-left mb-24'>
      <h2 className='text-4xl font-bold text-black mb-4'>What’s the plan?</h2>
      <ul className='list-disc ml-6 space-y-2 text-black text-xl'>
        <li>
          <strong>120M $RAI</strong> distributed over <strong>21 days</strong>.
        </li>
        <li>
          First <strong>100% community-owned meme</strong> on Vana.
        </li>
        <li>
          $RAI holders get a share of <strong>DLP rewards</strong>.
        </li>
      </ul>
      <p className='text-black mt-4 text-xl'>
        $RAI acts as a bribe token for DLP stakers, while rewards from YKYR's
        DLP are set to be distributed to $RAI holders. This means that those who
        stake to YKYR may earn more $VANA.
      </p>
    </section>
  )
}
const Tokenomics = () => {
  return (
    <section className='text-left mb-24'>
      <h2 className='text-4xl font-bold text-black mb-4'>Tokenomics</h2>
      <div className='text-left'>
        <img
          src='/images/rai-tokenomics.png'
          alt='RAI'
          className='mx-auto rounded-lg mb-4'
        />
      </div>

      <ul className='list-disc ml-6 space-y-2 text-black text-xl'>
        <li>
          <strong>Ticker:</strong> $RAI
        </li>
        <li>
          <strong>CA:</strong> 0x4727a2aeD28f3144E3d6Ba194296Bc512adBa2ca
        </li>
        <li>
          <strong>Total Supply:</strong> 120,000,000 $RAI
        </li>
        <li>
          <strong>% to Community:</strong> 100%
        </li>
      </ul>
    </section>
  )
}

const MainView = () => {
  return (
    <>
      <div className='text-center'>
        <img
          src='/images/rai-stone-500x.png'
          alt='RAI'
          className='w-1/2 mx-auto rounded-lg mb-4'
        />
      </div>

      <h3 className='text-4xl font-bold text-black mb-16'>
        Welcome to Vana
        <br />
        MAINNET Party!
      </h3>

      <div className='text-left'>
        <p className='text-xl text-black mb-24'>
          As part of Vana’s DLP, we’re excited to celebrate the launch of Vana’s
          mainnet together with our incredible community.
          <br />
          Vana’s mainnet explorer is called Islander, a name that symbolizes “a
          resident of an island.” To mark this special occasion, we’re
          presenting the community with a unique gift: stone money.
          <br />
          Known as RAI, this traditional stone currency was once used by the
          people of the Micronesian islands. Unlike modern money, it represented
          a heartfelt token of gratitude among islanders.
          <br />
          By paying homage to this ancient tradition, we aim to celebrate Vana’s
          TGE alongside all of you, our amazing communities.
        </p>
      </div>

      <HowToEarnRAI />
      <WhatPlan />
      <Tokenomics />
    </>
  )
}

const WindowCountdown = ({ isLocked, remainingTime, currentDay, maxDay }) => {
  const formatTime = time => {
    if (!time) return { hours: '00', minutes: '00', seconds: '00' }
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60
    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    }
  }

  if (isLocked) {
    if(currentDay > maxDay){
    } else {
      return (
        <div className='text-2xl text-black'>
          Calculating Reward Distribution...
          <br />
          The next window opens soon.
        </div>
      )
    }
  }

  const formattedTime = formatTime(remainingTime)

  return (
    <div className='flex justify-center space-x-2 text-center'>
      <div>
        <div className='text-6xl font-mono text-black mb-2'>
          {formattedTime.hours}
        </div>
        <div className='text-sm text-black opacity-50 uppercase'>Hours</div>
      </div>
      <div className='text-6xl text-black'>:</div>
      <div>
        <div className='text-6xl font-mono text-black mb-2'>
          {formattedTime.minutes}
        </div>
        <div className='text-sm text-black opacity-50 uppercase'>Minutes</div>
      </div>
      <div className='text-6xl text-black'>:</div>
      <div>
        <div className='text-6xl font-mono text-black mb-2'>
          {formattedTime.seconds}
        </div>
        <div className='text-sm text-black opacity-50 uppercase'>Seconds</div>
      </div>
    </div>
  )
}

const ConnectView = ({ onConnect, loading, error }) => {
  return (
    <div className='max-w-lg mx-auto'>
      <button
        onClick={onConnect}
        disabled={loading}
        className='w-full bg-white text-black rounded-2xl py-6 px-6 text-2xl font-bold
            hover:bg-white/90 disabled:opacity-50 transition-colors flex items-center justify-center'
      >
        <Wallet className='mr-2 h-6 w-6' />
        {loading ? 'Connecting...' : 'Connect Wallet'}
      </button>

      {error && <p className='text-red-400 text-center mt-4'>{error}</p>}
    </div>
  )
}

const JoinView = ({
  wallet,
  onJoin,
  loading,
  error,
  status,
  isLocked,
  remainingTime,
  goToClaim,
  currentDay,
  maxDay
}) => {
  const getButtonConfig = () => {
    if (loading) { 
      return {
        label: 'Joining...', 
        onClick: onJoin,
        disabled: true 
      }
    }

    if (status?.message === "You've already joined the Party!") {
      return {
        label: 'Start Claim',
        onClick: goToClaim,
        disabled: true
      }
    } else if (currentDay > maxDay) {
      return {
        label: 'Party closed!',
        onClick: null,
        disabled: true
      }
    }
    return {
      label: 'Join Party',
      onClick: onJoin,
      disabled: loading || isLocked
    }
  }

  const buttonConfig = getButtonConfig()

  return (
    <div className='text-center'>
      {/* Conditionally render the title */}
      <h1 className='text-6xl font-bold text-black mb-8'>
        {currentDay <= maxDay ? 'Join the Party' : 'Party Closed'}
      </h1>
      
      {/* Conditionally render the first two blocks */}
      {currentDay <= maxDay && (
        <>
          <p className='text-2xl text-black mb-4'>
            ・Join once and you're in! Stake more anytime to boost your rewards.
          </p>
          <p className='text-2xl text-black mb-16'>
            ・The party window is open for 23 hours daily. If it's closed for reward
            calculation, come back later and don’t miss out on the next day!
          </p>
        </>
      )}

      <div className='max-w-lg mx-auto space-y-4'>

        <WindowCountdown isLocked={isLocked} remainingTime={remainingTime} currentDay={currentDay} maxDay={maxDay}/>

        <div className='p-6 bg-white/10 rounded-2xl'>
          <p className='text-black font-mono break-all'>
            {wallet?.accounts[0].address}
          </p>
        </div>

        <button
          onClick={buttonConfig.onClick}
          disabled={buttonConfig.disabled}
          className={`w-full bg-white text-black rounded-2xl py-6 px-6 text-2xl font-bold
            hover:bg-white/90 disabled:opacity-50 transition-colors flex items-center justify-center`}
        >
          {buttonConfig.label}
        </button>

        {error && <p className='text-red-400 text-center mt-4'>{error}</p>}
        {status?.message && (
          <p
            className={`text-center mt-4 ${
              status.type === 'success' ? 'text-green-400' : 'text-red-400'
            }`}
          >
            {status.message}
          </p>
        )}
      </div>
    </div>
  )
}

const ClaimView = ({
  wallet,
  onClaimRewards,
  isLocked,
  remainingTime,
  daysOptions,
  currentDay,
  rewardPool,
  maxDay
}) => {
  const [selectedDay, setSelectedDay] = useState(null)
  const [claimStatus, setClaimStatus] = useState(null)
  const [isClaiming, setIsClaiming] = useState(false)


  const handleClaimRewards = async day => {
    if (isClaiming || isLocked || day >= currentDay) return // Prevent claim process if locked or already claiming
    try {
      setClaimStatus(null)
      setIsClaiming(true)
      const result = await onClaimRewards(day)
      setClaimStatus('success')
  
      setTimeout(() => {
        setClaimStatus(null)
      }, 3000)
    } catch (error) {
      console.error('Claim error:', error)
      setClaimStatus('error')
  
      setTimeout(() => {
        setClaimStatus(null)
      }, 3000)
    } finally {
      setIsClaiming(false)
    }
  }
  

  const formatGridButton = day => {
    const dayOption = daysOptions.find(opt => opt.day === day)
    const isClaimed = dayOption?.claimed
    const hasReward =
      dayOption?.rewardAmount && parseFloat(dayOption.rewardAmount) > 0

    const isSelected = selectedDay === day
    const isPastDay = day < currentDay 

    return {
      onClick: () => isPastDay && setSelectedDay(day),
      className:`
        w-16 h-16 rounded-lg flex items-center justify-center text-xl font-medium 
        ${
          isClaimed
            ? 'bg-gray-200 text-gray-500' // Lighter background for claimed rewards
            : hasReward
            ? 'bg-yellow-300 text-[#7A9BDE]' // Yellow background for unclaimed rewards
            : 'bg-gray-600/30 text-gray-400' // Default for no reward
        }
        ${
          isSelected
            ? 'border-4 border-yellow-400' // Highlight the selected day with yellow border
            : 'border-2 border-transparent' // Default border
        }
        ${!isPastDay ? 'cursor-not-allowed opacity-50' : ''} // Visually indicate unclickable future days
        transition-all duration-200
      `
    }
  }

  //const Spinner = () => {
  //  return (
  //    <div className="flex items-center justify-center">
  //      <div className="w-6 h-6 border-4 border-t-4 border-gray-300 rounded-full animate-spin"></div>
  //    </div>
  //  )
  //}

  return (
    <div className='flex flex-col items-center justify-center space-y-8'>
      <div className='text-center space-y-4'>
        <h1 className='text-6xl font-bold text-black'>Congratulations!</h1>
      </div>
      {maxDay !== null && currentDay > maxDay ? (
        <>
        <p className='text-3xl text-[#856421]'>
          Party ended! 
        </p>
        <p className='text-xl text-gray-600'>
          Claim your rewards before the timer runs out.
          </p>
          </>
      ) : (
      <div className='space-y-4 text-center'>
        <h2 className='text-3xl text-black'>
          Day {currentDay !== null ? currentDay : 'Loading...'} Reward Pool
        </h2>
        <p className='text-5xl font-bold text-[#856421]'>
          {rewardPool
            ? `${Number(rewardPool).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })} $RAI`
            : 'Loading...'}
        </p>
      </div>
      )}

      {claimStatus && (
        <div
          className={`
          fixed top-4 right-4 p-4 rounded-lg text-black
          ${claimStatus === 'success' ? 'bg-green-500' : 'bg-red-500'}
          transition-all duration-300
        `}
        >
          {claimStatus === 'success'
            ? 'Claim successful!'
            : 'Claim failed. Please try again.'}
        </div>
      )}

      <WindowCountdown isLocked={isLocked} remainingTime={remainingTime} currentDay={currentDay} maxDay={maxDay}/>

      <div className='grid grid-cols-7 gap-3 max-w-3xl mx-auto p-4'>
        {Array.from({ length: 21 }, (_, i) => i + 1).map(day => {
          const { onClick, className } = formatGridButton(day)
          return (
            <button key={day} onClick={onClick} className={className}>
              {day}
            </button>
          )
        })}
      </div>

      <div className='w-full max-w-md space-y-4'>

        <div className='bg-white/5 rounded-lg p-6 text-center'>
         {selectedDay ? (
            <>
              {/* Left corner: Day value */}
              <div className="text-xl text-black font-bold">
                Day {selectedDay}
              </div>

              {/* Right corner: RAI amount */}
              <div className="text-xl text-black">
                {daysOptions.find(opt => opt.day === selectedDay)?.rewardAmount || '0'} $RAI
              </div>
            </>
          ) : (
            <div className="text-xl text-black w-full text-center">
              Select a Day to Claim Rewards
            </div>
          )}
        </div>


        <button
          onClick={() => selectedDay && handleClaimRewards(selectedDay)}
          disabled={
            isLocked ||
            !(parseFloat(daysOptions.find(opt => opt.day === selectedDay)?.rewardAmount) > 0) ||
            !selectedDay ||
            daysOptions.find(opt => opt.day === selectedDay)?.claimed ||
            isClaiming ||
            (isLocked && currentDay > maxDay)
          }
          className={`
    w-full bg-white text-black rounded-2xl py-6 px-6 text-2xl font-bold
    hover:bg-white/90 disabled:opacity-50 transition-colors flex items-center justify-center
    ${
      !selectedDay ||
      isLocked ||
      daysOptions.find(opt => opt.day === selectedDay)?.claimed ||
      isClaiming
        ? 'bg-gray-600 text-gray-400 cursor-not-allowed'
        : 'bg-blue-600 text-black hover:bg-blue-700'
    }
  `}
        >
          {isClaiming
            ? 'Claiming ...'
            : daysOptions.find(opt => opt.day === selectedDay)?.claimed
            ? 'Already claimed!'
            : isLocked && currentDay > maxDay
            ? 'Claim closed' // New condition for claim period ended
            : 'Claim'}
        </button>
      </div>
    </div>
  )
}

const CLOSE_INTERVAL = 60 * 1000 // 1 minute
const NEAR_LOCK_WINDOW = 5 * 60 * 1000 // 5 minutes before lock

const VanaParty = () => {
  const [currentView, setCurrentView] = useState(VIEW_STATES.CONNECT)
  const [wallet, setWallet] = useState(null)
  const [status, setStatus] = useState({ type: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [isLocked, setIsLocked] = useState(false)
  const [remainingTime, setRemainingTime] = useState(null)
  const [daysOptions, setDaysOptions] = useState([])
  const [currentDay, setCurrentDay] = useState(null)
  const [rewardPool, setRewardPool] = useState(null)
  const [maxDay, setMaxDay] = useState(null)


  const fetchChainConfig = useCallback(async () => {
    const response = await fetch(`/api/config`)
    if (!response.ok) {
      throw new Error('Failed to fetch join status')
    }
    const config = await response.json()
    console.log('config: ', config)
    return config
  }, [])


  const fetchDataAndSchedule = useCallback(async () => {
    if (!wallet?.accounts[0]?.address) return

    try {
      console.log('Fetching data for wallet:', wallet.accounts[0].address)
      const config = await fetchChainConfig()
      const provider = new ethers.BrowserProvider(wallet.provider)
      const contract = new ethers.Contract(
        config.TOKEN_CONTRACT,
        raiAbi,
        provider
      )
      const emissionPerDay = await contract.emissionPerDay()
      const formattedEmission = ethers.formatUnits(emissionPerDay, 18) // RAI is 18 decimals

      const [currentDayResponse, rewardsResponse] = await Promise.all([
        axios.get(`/api/currentDay`),
        axios.get(`/api/userTotalRewards`, {
          params: { userAddress: wallet.accounts[0].address }
        })
      ])

      console.log('Current day response:', currentDayResponse.data)

      const maxDayValue = parseInt(config.EMISSION_DAYS, 10)
      setMaxDay(maxDayValue)
      const currentDayNumber = currentDayResponse.data.currentDay
      
      if (currentDayNumber) {
        setCurrentDay(currentDayNumber)
        setRewardPool(currentDayNumber > maxDayValue ? 0 : formattedEmission)
      }

      if (rewardsResponse.data) {
        const updatedDaysArray = Array.from(
          { length: maxDayValue },
          (_, i) => i + 1
        ).map(day => {
          const reward = rewardsResponse.data.find(
            r => Number(r.day_id) === day
          )
          return {
            day,
            rewardAmount: reward?.reward_amount
              ? parseFloat(
                  ethers.formatUnits(reward.reward_amount, 18)
                ).toFixed(3)
              : '0',
            claimed: reward?.claimed
          }
        })
        setDaysOptions(updatedDaysArray)
        console.log('updatedDaysArray :', updatedDaysArray)
      }
      // Fetch lockTimestamp
      const lockTimestampBN = await contract.lockTimestamp()
      const lockTime = Number(lockTimestampBN) // 修正箇所
      const currentTime = Math.floor(Date.now() / 1000)

      console.log('Lock time:', lockTime)
      console.log('Current time:', currentTime)

      if (lockTime <= currentTime) {
        setIsLocked(true)
        setRemainingTime(null)
      } else {
        setIsLocked(false)
        setRemainingTime(lockTime - currentTime)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      throw error
    }
  }, [wallet, fetchChainConfig])

  //checkAndScheduleUpdate
  const checkAndScheduleUpdate = useCallback(async () => {
    if (!wallet || !wallet.provider) {
      console.warn('Wallet or wallet provider is not available.')
      return CLOSE_INTERVAL
    }
    try {
      const config = await fetchChainConfig()
      const provider = new ethers.BrowserProvider(wallet.provider)
      const contract = new ethers.Contract(
        config.TOKEN_CONTRACT,
        raiAbi,
        provider
      )

      const lockTimestampBN = await contract.lockTimestamp()
      const lockTime = Number(lockTimestampBN)
      const currentTime = Math.floor(Date.now() / 1000)

      console.log('Lock time:', lockTime)
      console.log('Current time:', currentTime)

      if (lockTime <= currentTime) {
        setIsLocked(true)
        setRemainingTime(null)
        await fetchDataAndSchedule()
        return CLOSE_INTERVAL
      } else {
        setIsLocked(false)
        // 秒単位での残り時間を設定
        setRemainingTime(lockTime - currentTime)

        const timeUntilLock = (lockTime - currentTime) * 60 * 1000 // mil sec
        if (timeUntilLock <= NEAR_LOCK_WINDOW) {
          await fetchDataAndSchedule()
          return CLOSE_INTERVAL
        } else {
          const nextCheck = Math.max(
            timeUntilLock - NEAR_LOCK_WINDOW,
            CLOSE_INTERVAL
          )
          await fetchDataAndSchedule()
          return nextCheck
        }
      }
    } catch (error) {
      console.error('Error checking lock timestamp:', error)
      return CLOSE_INTERVAL
    }
  }, [wallet, fetchChainConfig, fetchDataAndSchedule])
  

  const ensureCorrectNetwork = async provider => {
    try {
      await provider.send('wallet_addEthereumChain', [
        {
          chainId: VANA_CHAIN.id,
          chainName: 'Vana',
          nativeCurrency: {
            name: 'VANA',
            symbol: 'VANA',
            decimals: 18
          },
          rpcUrls: [RPC_URL],
          blockExplorerUrls: ['https://islander.vanascan.io/']
        }
      ])

      await provider.send('wallet_switchEthereumChain', [
        { chainId: VANA_CHAIN.id }
      ])
    } catch (error) {
      console.error('Network switch failed:', error)
      throw new Error('Failed to switch to Vana network')
    }
  }

  const connectWallet = async () => {
    try {
      setLoading(true)
      setError(null)
      const wallets = await onboard.connectWallet()

      if (wallets[0]) {
        const provider = new ethers.BrowserProvider(wallets[0].provider)
        await ensureCorrectNetwork(provider)
        setWallet(wallets[0])

        const config = await fetchChainConfig();

        const contract = new ethers.Contract(
          config.TOKEN_CONTRACT,
          raiAbi,
          provider
        )
        const joinStatus = await contract.isPartyMember(wallets[0].accounts[0].address); 

        if (joinStatus) {
          setStatus({
            type: 'success',
            message: "You've already joined the Party!"
          })
          setCurrentView(VIEW_STATES.CLAIM)
        } else {
          setStatus({
            type: 'success',
            message: 'Connected to wallet successfully'
          })
          setCurrentView(VIEW_STATES.JOIN)
        }
      }
    } catch (error) {
      setError(error.message || 'Failed to connect wallet')
    } finally {
      setLoading(false)
    }
  }

  const updateJoinStatus = async walletAddress => {
    const response = await fetch(`/api/join`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json'
       },
      body: JSON.stringify({ walletAddress })
    })
    if (!response.ok) {
      throw new Error('Failed to update join status')
    }
    console.log("Updated join status!");
    return await response.json()
  }

  async function joinParty () {
    if (!wallet) return
    try {
      setLoading(true)
      setError(null)
      const provider = new ethers.BrowserProvider(wallet.provider)
      const signer = await provider.getSigner()
      const config = await fetchChainConfig()
      const tokenContract = new ethers.Contract(
        config.TOKEN_CONTRACT,
        raiAbi,
        signer
      )

      console.log('Sending joinParty transaction...')
      const tx = await tokenContract.joinParty()

      console.log('Waiting for transaction to be mined...')
      const receipt = await tx.wait()
      console.log('Joining on-chain confirmed')

      await updateJoinStatus(wallet.accounts[0].address)
      console.log('Joined successfully')

      setStatus({
        type: 'success',
        message: `Successfully joined the party!`
      })
      setCurrentView(VIEW_STATES.CLAIM)
    } catch (error) {
      let errorMessage = 'Failed to join the party'
      if (error.reason) {
        switch (error.reason) {
          case 'Joining is closed':
            errorMessage =
              'Joining the party is currently not allowed. Please wait for the next window.'
            break
          case 'Already joined':
            errorMessage = 'You have already joined the party.'
            setStatus({
              type: 'info',
              message: errorMessage
            })
            break
          case 'Minimum stake not met':
            errorMessage =
              'You do not meet the minimum stake required to join the party.'
            break
          default:
            errorMessage = `Transaction failed: ${error.reason}`
        }
      }
      if (error.reason === 'Already joined') {
        setCurrentView(VIEW_STATES.CLAIM)
      } else {
        setError(errorMessage)
      }
    } finally {
      setLoading(false)
    }
  }

  const claimRewards = async day => {
    try {
      if (!wallet || !day) return
      setLoading(true)
      setError(null)

      const userAddress = wallet.accounts[0].address
      console.log(
        `Fetching rewards for userAddress: ${userAddress}, day: ${day}`
      )

      const response = await axios.get(`/api/userRewards`, {
        params: { userAddress, day }
      })

      if (!response.data) {
        throw new Error('No rewards data returned from the server.')
      }

      const { rewardAmount, proofs } = response.data
      console.log('Rewards Data Retrieved:', response.data)
      const config = await fetchChainConfig()
      const provider = new ethers.BrowserProvider(wallet.provider)
      const signer = await provider.getSigner()
      const contract = new ethers.Contract(
        config.TOKEN_CONTRACT,
        raiAbi,
        signer
      )

      const hasClaimedToday = await contract.hasClaimedDay(day, userAddress);
      if (hasClaimedToday){
        setStatus({
          type: 'success',
          message: 'Rewards already claimed!'
        })
        await updateRewards(day, userAddress, signer)
        await fetchDataAndSchedule()
        return 
      }

      console.log(
        `Claiming rewards with day: ${day}, amount: ${rewardAmount}, proofs:`,
        proofs
      )

      // Generate a message with a timestamp
      const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
      const message = `Claim reward for day ${String(day)} for address ${userAddress.toLowerCase()} at ${String(timestamp)}`;
      console.log('Message to sign:', message);

      // Sign the message using the wallet's signer
      const signedMessage = await signer.signMessage(message);
      console.log('Signed message:', signedMessage);

      const tx = await contract.claimRewards(
        BigInt(day),
        BigInt(rewardAmount),
        proofs
      )

      //const emissionPerDay = await contract.emissionPerDay()

      console.log('Transaction sent. Waiting for confirmation...')
      const receipt = await tx.wait()

      console.log('Transaction confirmed:', receipt)
      setStatus({
        type: 'success',
        message: 'Rewards claimed successfully!'
      })

      await updateRewards(day, userAddress, timestamp, signedMessage)
      await fetchDataAndSchedule()

      return receipt
    } catch (error) {
      console.error('Error claiming rewards:', error)
      setError(error.message || 'Failed to claim rewards')
      throw error
    } finally {
      setLoading(false)
    }
  }

  const updateRewards = async (dayId, userAddress, timestamp, signedMessage) => {
    try {
      // Make the API call with the signed message
      const response = await fetch(`/api/claimed-reward`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dayId, userAddress, timestamp, signedMessage })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to update claim status.')
      }

      console.log('Reward claim updated successfully:', data)
      return { success: true, message: data.message }
    } catch (error) {
      console.error('Error updating claim status:', error)
      return { success: false, message: error.message }
    }
  }

  // Centralized scheduling handled by VanaParty
  useEffect(() => {
    let timeoutId = null
    let isSubscribed = true

    const scheduleNextUpdate = async () => {
      if (!wallet?.provider || !isSubscribed) return

      try {
        const nextInterval = await checkAndScheduleUpdate()

        if (isSubscribed) {
          timeoutId = setTimeout(scheduleNextUpdate, nextInterval)
        }
      } catch (error) {
        console.error('Error in update cycle:', error)
        if (isSubscribed) {
          timeoutId = setTimeout(scheduleNextUpdate, CLOSE_INTERVAL)
        }
      }
    }

    if (wallet?.provider) {
      scheduleNextUpdate()
    }

    return () => {
      isSubscribed = false
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [wallet, checkAndScheduleUpdate])

  // Countdown timer
  useEffect(() => {
    let intervalId = null

    if (remainingTime !== null && !isLocked) {
      intervalId = setInterval(() => {
        setRemainingTime(prev => {
          if (!prev || prev <= 1) {
            clearInterval(intervalId)
            checkAndScheduleUpdate() // Re-check when time runs out
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [remainingTime, isLocked])

  useEffect(() => {
    return () => {
      const disconnect = async () => {
        const [primaryWallet] = onboard.state.get().wallets
        if (primaryWallet) {
          await onboard.disconnectWallet({ label: primaryWallet.label })
        }
      }
      disconnect()
    }
  }, [])

  const goToClaim = () => setCurrentView(VIEW_STATES.CLAIM)

  const StepProgress = ({ currentView }) => {
    const steps = [
      { num: '1', text: 'Connect Wallet', state: VIEW_STATES.CONNECT },
      { num: '2', text: 'Join Party', state: VIEW_STATES.JOIN },
      { num: '3', text: 'Claim', state: VIEW_STATES.CLAIM }
    ]

    return (
      <div className='relative h-48 mb-4'>
        <div className='absolute left-1/2 -translate-x-1/2 w-full max-w-[420px]'>
          <div className='relative flex justify-between items-center'>
            {/* 線のコンテナ */}
            <div className='absolute top-8 left-0 w-full'>
              {/* 線を短くして、丸の端にぴったり合わせる */}
              <div
                className='absolute h-0.5 bg-white'
                style={{
                  left: '62px', // 丸の直径(64px) - 2px
                  width: '100px' // 間隔を固定値に
                }}
              />
              <div
                className='absolute h-0.5 bg-white'
                style={{
                  left: 'calc(50% + 32px)', // 中央の丸の右端から
                  width: '100px' // 間隔を固定値に
                }}
              />
            </div>

            {/* ステップサークル */}
            <div className='relative z-10 w-full flex justify-between'>
              {steps.map(step => (
                <div
                  key={step.num}
                  className='flex flex-col items-center'
                  style={{ width: '64px' }}
                >
                  <div
                    className={`
                      w-16 h-16 rounded-full flex items-center justify-center mb-3
                      ${currentView === step.state ? 'bg-white' : 'bg-white/50'}
                    `}
                  >
                    <span className='text-[#1a1464] text-2xl font-bold'>
                      {step.num}
                    </span>
                  </div>
                  <span className='text-black text-base sm:text-lg font-light text-center whitespace-nowrap'>
                    {step.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderView = () => {
    switch (currentView) {
      case VIEW_STATES.CONNECT:
        return (
          <ConnectView
            onConnect={connectWallet}
            loading={loading}
            error={error}
          />
        )
      case VIEW_STATES.JOIN:
        return (
          <JoinView
            wallet={wallet}
            onJoin={joinParty}
            loading={loading}
            error={error}
            status={status}
            isLocked={isLocked}
            remainingTime={remainingTime}
            goToClaim={goToClaim}
            currentDay={currentDay}
            maxDay={maxDay}
          />
        )
      case VIEW_STATES.CLAIM:
        return (
          <ClaimView
            wallet={wallet}
            onClaimRewards={claimRewards}
            isLocked={isLocked}
            remainingTime={remainingTime}
            daysOptions={daysOptions}
            currentDay={currentDay}
            rewardPool={rewardPool}
            maxDay={maxDay}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='min-h-screen bg-[#7A9BDE] py-8 px-4'>
      <div className='max-w-[780px] mx-auto'>
        <div className='mb-12 flex justify-center'>
          <RaiLogo className='text-black w-1/4' />
        </div>

        {currentView === VIEW_STATES.CONNECT && <MainView />}
        <StepProgress currentView={currentView} />

        {renderView()}
      </div>
    </div>
  )
}

export default VanaParty
