import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import Privacy from './legal/privacy'
import Terms from './legal/terms'
import Extension from './extension/extension'
import Premine from './premine/premine'
import VanaParty from './vanaparty/VanaParty'

const RedirectToExternal = () => {
  React.useEffect(() => {
    window.location.href = 'https://datahub.vana.org/'
  }, [])
  return null
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/terms',
    element: <Terms />
  },
  {
    path: '/extension',
    element: <Extension />
  },
  {
    path: '/premine',
    element: <Premine />
  },
  {
    path: '/vanaparty',
    element: <VanaParty />
  },
  {
    path: '/vanaairdrop',
    element: <RedirectToExternal />
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_prependBasename: true,
    v7_skipActionErrorRevalidation: true
  }
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)